import React, { useEffect } from "react";
import home from "../assets/press.png";
import HomeButton from "./HomeButton";
import { isMobile } from "react-device-detect";
import mobilePress from "../assets/mobilePress.png";

export default function Press() {
  useEffect(() => {
    localStorage.setItem("press_seen", `${Date.now()}`);
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${!isMobile ? home : mobilePress})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        style={{
          padding: isMobile ? "0 2.5vw" : "0 5vw",
          paddingBottom: "5vh",
          overflowY: "scroll",
          overflow: "auto",
          height: "100vh",
          textAlign: "left",
        }}
      >
        <HomeButton />
        <p
          style={{
            marginTop: "5vh",
            marginBottom: "2vh",
          }}
          className={isMobile ? "mobile_bold_para" : "bold_para"}
        >
          dear friends,
        </p>
        <p className={isMobile ? "mobile_para" : "para"}>
          when Brian passed in late 2017, our lives fundamentally changed and we
          needed time to heal. for us (and many others), these songs turned into
          a memory of a dear friend, son, and collaborator who is no longer with
          us… and that is hard. as we stepped away, you all continued to show up
          and remind us of the power of this music. you showed us that we're not
          the only ones who saw brian's beautiful mind and the magic that
          happened on stage and in the studio. your support has been deeply
          healing and has given us space to grow through a deeper connection to
          the music and each other. without you, blue smiley would be a
          complicated memory of what could have been. although we'll always have
          unanswered questions, we do know one thing:
        </p>
        <p
          style={{ fontStyle: "italic" }}
          className={isMobile ? "mobile_bold_para" : "bold_para"}
        >
          Blue Smiley needs a long lasting legacy.
        </p>
        <p className={isMobile ? "mobile_para" : "para"}>
          that is why we're incredibly excited to announce that we have
          partnered with Topshelf Records to reissue physical versions of our
          two LP's: 'ok' and 'return.' physicals are now available for pre-order
          on all formats and a new official music video for our previously
          unreleased song, 'coma' is now out. here's the details:
        </p>
        <p className={isMobile ? "mobile_para" : "para"}>
          <ul>
            <li>
              vinyl and CD versions of 'ok' will include some new artwork done
              by bissie loux
            </li>
            <li>
              vinyl and CD versions of 'return' will also include some new
              artwork done by our original cover artist, emily daly
            </li>
            <li>
              a new music video for our previously unreleased song, 'coma,' is
              now out. directed and edited by Josh Mackie and Dan Angel, with
              text by Alan 'Rickman' Official. this track was originally a bonus
              track for 'return' and is now available across digital platforms
              as well as on all 'return' physicals
            </li>
            <li>
              remastered cover art for 'ok,' 'return,' and 'coma' all now exist
              across digital platforms, done by sarah alvarez
            </li>
          </ul>
        </p>
        <p className={isMobile ? "mobile_para" : "para"}>
          we are also ecstatic to welcome Ken Nowell (Brian's Dad) to the band
          as our managing member and accountant — we couldn't have done this
          without you, Ken! HUGE thank you to Topshelf Records for being
          incredibly supportive and patient as we've navigated this wildly
          complicated project. we're looking forward to working with such a
          badass crew of people to expand our offering to the world!
        </p>
        <p className={isMobile ? "mobile_para" : "para"}>
          thank you to Ken and Karen Nowell, Andres Villogas, Will Osiecki,
          Kevin Duquette, Sarah Alvarez, Seth Decoteau, Matt McGraw, Mike Corso,
          Bissie Loux, Emily Daly, Cat Lucey-Meagher, Caleb Griffith, and so
          many more for the endless amount of work and trust that continues to
          go into this project.
        </p>
        <p className={isMobile ? "mobile_bold_para_padding" : "bold_para"}>
          thx y'all, much love!
        </p>
        <p className={isMobile ? "mobile_bold_para_padding" : "bold_para"}>
          - blue smiley
        </p>
      </div>
    </div>
  );
}
