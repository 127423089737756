import React from "react";
import home from "../assets/listen.png";
import HomeButton from "./HomeButton";
import { isMobile } from "react-device-detect";
import mobileListen from "../assets/mobileListen.png";
import ok from "../assets/ok.jpg";
import cover from "../assets/cover.jpg";
import fishhh from "../assets/fish.jpg";
import kane from "../assets/kane.png";
import happy from "../assets/happy.jpg";
import coma from "../assets/coma.jpeg";

const links = [
  {
    icon: coma,
    title: "coma",
    link: "https://www.topshelfrecords.com/bluesmileyforever",
  },
  {
    icon: ok,
    title: "ok",
    link: "https://open.spotify.com/album/0rdqoGy3iMbJJDjiOKrywY?si=S5n0XGlkQXqrLoXd1J5Xlw",
  },
  {
    icon: cover,
    link: "https://open.spotify.com/album/2wSCeijPFFOhlfvOQhgdss?si=pmrC_xz_RqCJXcqvJEHcsQ",
    title: "return",
  },
  // {
  //   icon: fishhh,
  //   link: "https://bluesmiley.bandcamp.com/",
  //   title: "fishhh",
  // },

  // {
  //   icon: kane,
  //   link: "https://bluesmiley.bandcamp.com/",
  //   title: "Live on the Kane Konundrum",
  // },
  // {
  //   icon: happy,
  //   link: "https://bluesmiley.bandcamp.com/",
  //   title: "happy",
  // },
];

export default function Bio() {
  return (
    <div
      style={{
        backgroundImage: `url(${!isMobile ? home : mobileListen})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        style={{
          padding: isMobile ? "0 2.5vw" : "0 5vw",
          paddingBottom: "6vh",
          overflowY: "scroll",
          overflow: "auto",
          height: "100vh",
        }}
      >
        <HomeButton dark={!isMobile} />
        <h1
          style={{ color: isMobile ? "white" : "black" }}
          className={isMobile ? "mobile_title" : "title"}
        >
          listen
        </h1>

        {links.map((link, i) => (
          <a
            key={`${link.link}.${i}`}
            href={link.link}
            target="_blank"
            rel="noreferrer"
            style={{
              color: isMobile ? "white" : "black",
              textDecoration: "none",
            }}
          >
            <img
              src={link.icon}
              alt={link.title}
              style={{
                marginTop: i === 0 ? "2rem" : undefined,
                height: isMobile ? 300 : 500,
                width: isMobile ? 300 : 500,
                border: !isMobile ? "1px solid black" : "1px solid white",
                boxShadow: `2px 2px 2px ${isMobile ? "white" : "black"}`,
              }}
            />

            {isMobile ? (
              <h3
                style={{
                  padding: 0,
                  margin: 0,
                  marginBottom: i === links.length - 1 ? "7rem" : "2rem",
                }}
              >
                {link.title}
              </h3>
            ) : (
              <h1>{link.title}</h1>
            )}
          </a>
        ))}
      </div>
    </div>
  );
}
