import React from "react";
import { Link } from "react-router-dom";
import lion from "../assets/neg.png";
import { isMobile } from "react-device-detect";

export default function HomeButton({ dark = true }) {
  return (
    <Link to="/">
      <img
        style={{
          right: isMobile ? 10 : 20,
          top: isMobile ? 10 : 20,
          height: isMobile ? 50 : 120,
          padding: isMobile ? (!dark ? 1 : 10) : 20,
          width: isMobile ? 50 : 120,
          position: "absolute",
          borderRadius: !dark ? "50%" : undefined,
          backgroundColor: !dark ? "white" : undefined,
        }}
        src={lion}
        alt={"home"}
      />
    </Link>
  );
}
