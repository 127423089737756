import React, { useEffect } from "react";
import home from "../assets/store.png";
import mobileStore from "../assets/mobileStore.png";
import HomeButton from "./HomeButton";
import { isMobile } from "react-device-detect";

export default function Store() {
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    window.location = "https://bluesmiley.bandcamp.com/merch";
  }, []);

  return null;

  return (
    <div
      style={{
        backgroundImage: `url(${!isMobile ? home : mobileStore})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <HomeButton />
      <h1 className={isMobile ? "mobile_title" : "title"}>store</h1>

      <iframe
        id="store"
        style={{
          marginBottom: 100,
          display: show ? "block" : "none",
        }}
        onLoad={() => {
          const blah = document
            .getElementById("store")
            .contentWindow.document.getElementById();

          console.log(blah);
          if (blah) {
            blah.style.display = "none";
          }

          setShow(true);
        }}
        height={isMobile ? "100%" : "100%"}
        title="store"
        src="https://bluesmiley.bandcamp.com/merch"
        seamless
      />
    </div>
  );
}
