import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./screens/Home";
import Store from "./screens/Store";
import Bio from "./screens/Bio";
import Listen from "./screens/Listen";
import Press from "./screens/Press";
import Contact from "./screens/Contact";
import Error from "./screens/Error";

const router = createBrowserRouter([
  {
    path: "/",
    Component: Home,
    errorElement: <Error />,
  },
  {
    path: "/bio",
    Component: Bio,
  },
  {
    path: "/listen",
    Component: Listen,
  },
  {
    path: "/press",
    Component: Press,
  },
  {
    path: "/contact",
    Component: Contact,
  },
  {
    path: "/store",
    Component: Store,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
