import { useEffect } from "react";

export default function Error() {
  useEffect(() => {
    window.location = '/'
  }, [])

  return (
   null
  );
}