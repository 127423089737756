import React from "react";
import home from "../assets/bio.png";
import HomeButton from "./HomeButton";
import { isMobile } from "react-device-detect";
import mobileBio from "../assets/mobileBio.png";

export default function Bio() {
  return (
    <div
      style={{
        backgroundImage: `url(${!isMobile ? home : mobileBio})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div
        style={{
          padding: isMobile ? "0 2.5vw" : "0 5vw",
          paddingBottom: "5vh",
          overflowY: "scroll",
          overflow: "auto",
          height: "100vh",
          textAlign: "left",
        }}
      >
        <HomeButton />
        <h1
          style={{ textAlign: "center" }}
          className={isMobile ? "mobile_title" : "title"}
        >
          bio
        </h1>
        <p className={isMobile ? "mobile_para" : "para"}>
          Brian Nowell and John Slavin met in Music School in 2009. Bonding over
          a love for jazz and punk rock, they quickly became close friends. Four
          years later, Nowell and Slavin decided to move into the world of more
          experimental and distorted compositions. They came together with a
          shared love for short and punchy tunes.
        </p>
        <p className={isMobile ? "mobile_para" : "para"}>
          Towards the end of 2013, Blue Smiley began in Slavin and Nowell’s
          North Philadelphia apartment as a band name as well as Brian’s musical
          persona. During these early months of late 2013 through mid-2014, they
          were joined by drummers Gracie Clements, Tim McMonigle, and Joe
          Veltri. They performed in studio on The Kane Konundrum and released
          two short recordings, “fishhh” and “happyyy.” In 2015, they began
          working with Matt McGraw (a childhood friend of Slavin’s) on drums.
          They developed a sound that would become a defining feature of the
          band, with deep grooves and aggressive tempo changes.
        </p>
        <p className={isMobile ? "mobile_para" : "para"}>
          They then released their first professionally recorded album, “ok,”
          and began touring outside the Philadelphia area. It was in the middle
          of one of these tours, in the summer of 2016, that the group released
          their second album, “return.” By this time, Slavin had moved to
          Austin, TX, and Michael Corso took over on bass, a sound that would
          become foundational to the record. Josh Lesser, a friend of Brian’s,
          added guitar features on the tracks “glass” and “coma” (a bonus track
          previously only released with a full download of the album),” and Abi
          Reimold (vocal) provided a sweet balance on the track “idk.” Josh
          Lesser, Kieran Ferris, Tim McMonigle all became defining parts of the
          touring group’s sound.
        </p>
        <p className={isMobile ? "mobile_para" : "para"}>
          Throughout multiple tours, several new songs were introduced to the
          setlist. Celebrating his upcoming 27th birthday with his parents,
          Nowell expressed his intentions to return to the studio to put these
          tracks together for a third LP. Unfortunately, the following morning,
          October 8, 2017, one of Brian’s roommates found him unresponsive in
          his room, a victim of fentanyl poisoning.
        </p>
        <p
          style={{
            marginBottom: isMobile ? "7rem" : undefined,
          }}
          className={isMobile ? "mobile_para" : "para"}
        >
          As the fifth anniversary of Nowell’s passing approached, an unexpected
          surge in interest in Blue Smiley motivated his parents, Slavin, McGraw
          and Corso to pursue efforts to honor the life of Brian and Blue
          Smiley.
        </p>
      </div>
    </div>
  );
}
