import React, { useEffect, useState } from "react";
import lion from "../assets/lion.png";
import { isMobile } from "react-device-detect";

export default function PlayButton({
  isClose,
  handleClose,
  isWatch,
  handleOpen,
  setIsPlaying,
  isPlaying,
  audio,
}) {
  const [className, setClassName] = useState("");

  useEffect(() => {
    if (isWatch) {
      setTimeout(() => {
        if (isPlaying) {
          setClassName("");
        } else {
          setClassName("play_button");
        }
      }, 300);
    }
  }, [isWatch, isPlaying]);

  return (
    <div
      style={{
        cursor: "pointer",
        height: isMobile ? 50 : 75,
        width: isMobile ? 50 : 75,
        top: isWatch ? (isMobile ? 110 : 175) : 20,
        position: "absolute",
        right: isMobile ? 12 : 30,
        zIndex: 100000000000,
      }}
      className={
        isClose
          ? undefined
          : isPlaying
          ? undefined
          : isWatch
          ? className
          : "play_button"
      }
    >
      <button
        style={{
          cursor: "pointer",
          height: "100%",
          padding: 10,
          width: "100%",
          alignItems: "center",
          marginTop: isMobile ? 0 : 10,
          backgroundColor: "white",
          borderRadius: "50%",
          border: isMobile ? undefined : "solid 1px black",
        }}
        onClick={() => {
          if (isClose) {
            handleClose();
          } else if (isWatch) {
            if (isPlaying) {
              audio.pause();
              audio.currentTime = 0;
              setIsPlaying(false);
            }
            handleOpen();
          } else {
            if (isPlaying) {
              audio.pause();
              audio.currentTime = 0;
              setIsPlaying(false);
              return;
            } else {
              audio.play();
              setIsPlaying(true);
            }
          }
        }}
      >
        <img
          className={
            (isPlaying && !isWatch) || isClose ? "playing_button" : undefined
          }
          style={{
            height: "100%",
            width: "100%",
          }}
          src={lion}
          alt={"play"}
        />
        {isMobile ? (
          <h3
            style={{
              color: "white",
              fontFamily: "gloria",
              padding: 0,
              margin: 0,
              textAlign: "center",
              marginTop: 1,
            }}
          >
            {isClose
              ? "close"
              : isWatch
              ? "watch"
              : isPlaying
              ? "stop "
              : "listen"}
          </h3>
        ) : (
          <h1
            style={{
              color: "white",
              fontFamily: "gloria",
              padding: 0,
              margin: 0,
              marginTop: 10,
            }}
          >
            {isClose
              ? "close"
              : isWatch
              ? "watch"
              : isPlaying
              ? "stop "
              : "listen"}
          </h1>
        )}
      </button>
    </div>
  );
}
