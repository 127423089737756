import React from "react";
import home from "../assets/contact.png";
import HomeButton from "./HomeButton";
import { isMobile } from "react-device-detect";
import mobileContact from "../assets/mobileContact.png";

export default function Contact() {
  return (
    <div
      style={{
        backgroundImage: `url(${!isMobile ? home : mobileContact})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div>
        <HomeButton />
        <h1 className={isMobile ? "mobile_title" : "title"}>contact</h1>
      </div>
    </div>
  );
}
