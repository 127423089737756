import "../App.css";
import home from "../assets/home.png";
import mobileHome from "../assets/mobileHome.png";
import { isMobile } from "react-device-detect";
import Links from "../components/LInks";
import brush from "../assets/brush.png";
import PlayButton from "./PlayButton";
import Modal from "react-modal";
import { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import van from "../assets/van.png";
import coma from "../assets/coma.wav";
import coma2 from "../assets/coma.jpeg";
import usePreloadImages from "../hooks/usePreloadImages";
import bio from "../assets/bio.png";
import contact from "../assets/contact.png";
import cover from "../assets/cover.jpg";
import fish from "../assets/fish.jpg";
import happy from "../assets/happy.jpg";
import kane from "../assets/kane.jpg";
import lion from "../assets/lion.png";
import desktopListen from "../assets/listen.png";
import mobileBio from "../assets/mobileBio.png";
import mobileContact from "../assets/mobileContact.png";
import mobileListen from "../assets/mobileListen.png";
import mobilePress from "../assets/mobilePress.png";
import mobileStore from "../assets/mobileStore.png";
import neg from "../assets/neg.png";
import ok from "../assets/ok.jpg";
import desktopPress from "../assets/press.png";
import store from "../assets/store.png";
import whiteSwipe from "../assets/white-swipe.png";
import hammock from "../assets/hammock.svg";

const imageList = [
  desktopPress,
  brush,
  // contact,
  cover,
  // fish,
  // happy,

  home,
  // kane,
  coma2,
  lion,
  desktopListen,
  mobileBio,
  // mobileContact,
  mobileListen,
  mobilePress,
  // mobileStore,
  neg,
  ok,
  desktopPress,
  // store,
  van,
  whiteSwipe,
].filter((img) =>
  isMobile ? !img.includes("desktop") : !img.includes("mobile")
);

export default function Home() {
  const { imagesPreloaded } = usePreloadImages(imageList);

  const [isOpen, setIsOpen] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showVan, setShowVan] = useState(false);

  const audio = useMemo(() => new Audio(coma), []);

  const handleClose = () => {
    localStorage.setItem("modal_seen", `${Date.now()}`);
    setIsOpen(false);
  };

  useEffect(() => {
    const modal_seen = localStorage.getItem("modal_seen");
    if (modal_seen) {
      const diff = Date.now() - parseInt(modal_seen);
      // 5 minutes
      if (diff > 1000 * 60 * 5) {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    const press_seen = localStorage.getItem("press_seen");
    if (press_seen) {
      const diff = Date.now() - parseInt(press_seen);
      // 5 minutes
      if (diff > 1000 * 60 * 5) {
        setShowVan(true);
      }
    } else {
      setShowVan(true);
    }
  }, []);

  return imagesPreloaded ? (
    <div
      style={{
        backgroundImage: `url(${!isMobile ? home : mobileHome})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <PlayButton
        audio={audio}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        isClose={isOpen}
        handleClose={handleClose}
      />
      {!isOpen && (
        <PlayButton
          audio={audio}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          isWatch
          handleOpen={() => setIsOpen(true)}
        />
      )}
      <div
        style={{
          backgroundImage: `url(${isMobile ? brush : undefined})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          height: 75,
          width: "100%",
        }}
      >
        {isMobile && <h1 style={{ color: "white" }}>blue smiley</h1>}
        <Links />
        <Modal
          className={"modal-test"}
          style={{
            margin: 0,
            padding: 0,
            border: 0,
          }}
          shouldCloseOnOverlayClick={true}
          isOpen={isOpen}
        >
          <div
            style={{
              display: "flex",
              padding: 0,
              margin: 0,
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              height: "100vh",
              width: "100%",
            }}
          >
            <iframe
              style={{
                height: "100%",
                width: "100%",
                alignItems: "center",
              }}
              src="https://player.vimeo.com/video/864187993?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1"
              title="blue smiley - coma"
              allow="autoplay"
            ></iframe>
          </div>
        </Modal>
      </div>

      {!isOpen && showVan && (
        <Link to={"/press"}>
          <div className="flier">
            <img
              alt="van"
              src={van}
              style={{
                height: isMobile ? 200 : 300,
                width: isMobile ? 200 : 300,
              }}
            />
          </div>
        </Link>
      )}
    </div>
  ) : (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        className: "loading",
        backgroundColor: "white",
      }}
    >
      <img
        src={hammock}
        alt="loading"
        className="loading"
        style={{ height: 200, width: 200 }}
      />
    </div>
  );
}
