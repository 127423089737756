import React from "react";
import { Link } from "react-router-dom";
import lion from "../assets/lion.png";
import { isMobile } from "react-device-detect";
import brush from "../assets/brush.png";

export default function Links() {
  const links = [
    {
      display: "about",
      path: "/bio",
      top: "5%",
      left: "5%",
    },
    {
      display: "listen",
      path: "/listen",
      top: "22%",
      left: "5%",
    },
    {
      path: "/press",
      display: "press",
      top: "40%",
      left: "5%",
    },
    {
      path: "#",
      display: "contact",
      top: "58%",
      left: "5%",
    },
    {
      path: "https://www.topshelfrecords.com/bluesmileyforever",
      display: "store",
      top: "76%",
      left: "5%",
    },
  ];

  return (
    <div
      style={{
        display: isMobile ? "flex" : undefined,
        flexDirection: isMobile ? "row" : undefined,
        alignItems: isMobile ? "center" : undefined,
        position: isMobile ? "absolute" : undefined,
        bottom: isMobile ? "2%" : undefined,
        width: isMobile ? "100%" : undefined,
        overflow: isMobile ? "scroll" : undefined,
      }}
      className="links"
    >
      {links.map((link) => (
        <Link
          key={link.path}
          to={link.path}
          onClick={
            link.display === "contact"
              ? () => (window.location.href = "mailto:itsbluesmiley@gmail.com")
              : undefined
          }
        >
          <div
            className="link"
            style={{
              display: "flex",
              flexDirection: "column",
              position: !isMobile ? "absolute" : undefined,
              top: !isMobile ? link.top : undefined,
              left: !isMobile ? link.left : undefined,
              height: isMobile ? "fit-content" : undefined,
              marginBottom: isMobile ? "3%" : undefined,
            }}
          >
            <div className={!isMobile ? "header" : undefined}>
              <div className={!isMobile ? "section-header" : undefined}>
                <div
                  className={!isMobile ? "text-wrapper" : "mobile-text-wrapper"}
                  style={
                    isMobile
                      ? {
                          backgroundImage: `url(${brush})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPositionX: "center",
                          backgroundPositionY: "bottom",
                          width: "20vw",
                        }
                      : undefined
                  }
                >
                  {!isMobile && (
                    <h1
                      style={{
                        fontSize: "1.5rem",
                        margin: 0,
                        lineHeight: 1,
                        color: "white",
                        position: "absolute",
                        top: -20,
                        left: 250,
                      }}
                    >
                      {link.display}
                    </h1>
                  )}

                  <img
                    style={{
                      transform: "rotate(" + Math.random() * 360 + "deg)",
                      height: isMobile ? 30 : 40,
                      width: isMobile ? 30 : 40,
                      marginTop: isMobile ? 0 : 5,
                      backgroundColor: "white",
                      borderRadius: "50%",
                      padding: isMobile ? 10 : 15,
                      border: isMobile ? undefined : "solid 1px black",
                    }}
                    className={"lion"}
                    src={lion}
                    alt={link.name}
                  />
                  {isMobile && (
                    <h1
                      style={{
                        fontSize: "1rem",
                        lineHeight: 1,
                        color: "white",
                        marginTop: 75,
                      }}
                    >
                      {link.display}
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
}
